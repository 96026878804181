/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

function set_parallax_steps_content_height() {
    jQuery('.section-parallax_scrolling_steps').each(function() {
        if (jQuery(window).width() >= 768) {
            var introHeight = jQuery('.section-intro', jQuery(this)).outerHeight();
            var windowHeight = jQuery(window).height();
            jQuery('.step-captions', jQuery(this)).height(windowHeight - introHeight);
            jQuery('.step-photos', jQuery(this)).height(windowHeight - introHeight);
        } else {
            jQuery('.step-captions', jQuery(this)).height('auto');
            jQuery('.step-photos', jQuery(this)).height('auto');
        }
    });
}

function reset_resource_tile_height() {
        setTimeout(function() {
            jQuery('.equalize-heights .item, .equalize-heights .sub-item').matchHeight({remove: true});
            jQuery('.equalize-heights .item, .equalize-heights .sub-item').matchHeight({remove: true});
            jQuery('.equalize-heights .item, .equalize-heights .sub-item').matchHeight({byRow: true});
            Waypoint.refreshAll();
        }, 300);
}

function initialize_marketo_form(mktoForm) {
    //Add a privacy policy link below the form submit button
    // $('.mktoButton', mktoForm).after('<div class="form-terms"><a href="/privacy" target="_blank">Privacy Policy</a></div>');

    //Add a column count class to all form rows.
    jQuery('.mktoFormRow', mktoForm).each(function() {
        jQuery(this).addClass('cols-' + jQuery('.mktoFormCol', jQuery(this)).length);

        if (jQuery('.mktoCheckboxList', jQuery(this)).length > 0) {
            jQuery(this).addClass('checkboxFormRow');
        }
    });
}

function getParameterByName(name, url) {
    if (!url) {
        url = window.location.href;
    }

    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"), results = regex.exec(url);

    if (!results) {
        return null;
    }

    if (!results[2]) {
        return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function getFormCookieParam(param){
    if (typeof(Cookies.get('padparams')) !== "undefined") {
        var pageURL = Cookies.get('padparams');
        var URLVariables = pageURL.split('&');

        for (var i = 0; i < URLVariables.length; i++) {
            var parameterName = URLVariables[i].split('=');
            if (parameterName[0] === param) {
                return parameterName[1];
            }
        }
    } else {
        var querystringParam = getParameterByName(param);

        if (querystringParam) {
            return querystringParam;
        }
    }

    return "";
}


//Set up the main ScrollMagic Controller and set its default to start at the top of the page.
var scrollMagicController = new ScrollMagic.Controller({
    globalSceneOptions: {
        triggerHook: 'onLeave'
    }
});

scrollMagicController.scrollPos(function () {
  if(window.innerWidth >= 768){
    return window.pageYOffset;
  } else {
    return 0;
  }
});

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Payfirma = {
    // All pages
    'common': {
      init: function() {
        $('.open-chat a').click(function(e) {
            olark('api.box.expand');
            e.preventDefault();
        });

        $('#section-nav-select').change(function() {
            window.location.href = $(this).val();
        });

        //Match the height of grid columns
        $('.equalize-content-heights .target-content').matchHeight({byRow: true});
        $('.equalize-heights .item, .equalize-heights .sub-item').matchHeight({byRow: true});
        $('.equalize-heights .block-photo').matchHeight({byRow: true});

        //Scroll to a given anchor point.
        $('.scroll-to-anchor').click(function(e) {
            var offset = 160;

            if ($(this).attr('data-offset')) {
                 offset = $(this).attr('data-offset');
            }

            var targetOffset = $($(this).attr('data-target')).offset();

            $('html, body').animate({
                scrollTop: targetOffset.top-offset
            }, 600);

            e.preventDefault();
        });

        //Initialize the Sidr menu.
        $('#sidr-menu-toggle').sidr({
            name: 'sidr-right',
            side: 'right',
            source: '#sidr-menu',
            renaming: false
        });

        $('.close-menu').on('click', function(e) {
            e.preventDefault();
            $.sidr('close', 'sidr-right');
        });

        //On resize we'll hide the sidr menu if it's still open.
        $(window).on('resize', function () {
            if($('body').hasClass('sidr-open') && $(window).width() >= 768) {
                $.sidr('close', 'sidr-right');
            }
        });

        $(".product-carousel").flickity({
            cellSelector: '.carousel-cell',
            pageDots : false,
            prevNextButtons: true,
            setGallerySize: true,
            wrapAround: true,
            autoPlay: true,
            arrowShape: {
              x0: 10,
              x1: 60, y1: 50,
              x2: 70, y2: 40,
              x3: 30
            }
        });

        if ($('.infinite-container').length) {
            //Set up infinite scroll using Waypoints
            var infinite = new Waypoint.Infinite({
                element: $('.infinite-container')[0],
                items: 'article.post',
                more: '.nav-previous a, .nav-links a.next',
                onAfterPageLoad: reset_resource_tile_height
            });
        }

        var $sidebar = $('.sticky-sidebar');

        if ($sidebar.length) {
            var offset = $sidebar.offset();

            $sidebar.affix({
                offset: {
                    top: offset.top-140,
                    bottom: ($('.form-header').outerHeight(true) + $('#summary-charts').outerHeight(true) +  $('.footer-form').outerHeight(true) + $('.landing-continue').outerHeight(true) + $('.landing-footer').outerHeight(true)) + 50
                }
            });
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'why_us': {
        init: function() {
            $(".why-us-carousel").flickity({
                cellSelector: '.carousel-cell',
                pageDots : true,
                setGallerySize: true,
                wrapAround: true,
                arrowShape: {
                  x0: 10,
                  x1: 60, y1: 50,
                  x2: 70, y2: 40,
                  x3: 30
                }
            });
        },
        finalize: function() {
            // JavaScript to be fired on all pages, after page specific JS is fired
        }
    }
};

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Payfirma;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
